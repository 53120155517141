import Axios from "axios";
import moment from "moment";
import "moment/locale/de";




export default class I18N
{
	static Index = -1;




	static Pick(translations: string[])
	{
		return translations[I18N.Index];
	}




	static ChangeTo(code: string)
	{
		Axios.defaults.headers["X-Language"] = code;
		moment.locale(code);
		I18N.Index = ["en", "de"].indexOf(code);
	}

	// tslint:disable: max-line-length whitespace

static get FIRST_NAME() {return I18N.Pick(["First name","Vorname"]);}
static get LAST_NAME() {return I18N.Pick(["Last name","Nachname"]);}
static get COMPANY() {return I18N.Pick(["Company","Unternehmen"]);}
static get EDIT() {return I18N.Pick(["Edit","Bearbeiten"]);}
static get DELETE() {return I18N.Pick(["Delete","Löschen"]);}
static get LOGOUT() {return I18N.Pick(["Logout","Abmelden"]);}
static get LOGIN() {return I18N.Pick(["Login","Anmelden"]);}
static get USERNAME() {return I18N.Pick(["Username","Benutzername"]);}
static get PASSWORD() {return I18N.Pick(["Password","Passwort"]);}
static get CAPACITY_SHARING() {return I18N.Pick(["Capacity Sharing","Kapazitätsausgleich"]);}
static get MY_CALENDAR() {return I18N.Pick(["My calendar","Mein Kalender"]);}
static get TASK_SHARING() {return I18N.Pick(["Task sharing","Kapazitätspool"]);}
static get COMPANIES() {return I18N.Pick(["Companies","Firmen"]);}
static get MY_PROFILE() {return I18N.Pick(["My profile","Mein Profil"]);}
static get USERS() {return I18N.Pick(["Users","Benutzer"]);}
static get SERVICES() {return I18N.Pick(["Services","Dienstleistungen"]);}
static get NEWS() {return I18N.Pick(["News","Nachrichten"]);}
static get PARTNERS_OVERVIEW() {return I18N.Pick(["Partners overview","Partner-Übersicht"]);}
static get PICK_A_UNIQUE_USERNAME() {return I18N.Pick(["Pick a unique username","Wählen Sie einen eindeutigen Benutzernamen"]);}
static get DO_YOU_WANT_TO_SIGN_UP_A_NEW_USER() {return I18N.Pick(["Do you want to sign up a new user","Wollen Sie einen neuen Benutzer registrieren"]);}
static get DO_YOU_WANT_TO_EDIT_YOUR_PROFILE() {return I18N.Pick(["Do you want to Edit your profile","Wollen Sie Ihr Profil bearbeiten"]);}
static get NICKNAME() {return I18N.Pick(["Nickname","Anzeigename"]);}
static get THIS_WILL_BE_PUBLICLY_AVAILABLE() {return I18N.Pick(["This will be publicly available","Dies wird öffentlich gezeigt"]);}
static get EMAIL() {return I18N.Pick(["Email","E-Mail"]);}
static get UNIQUE_EMAIL_ADDRESS() {return I18N.Pick(["Unique email address","Eindeutige E-Mail-Adresse"]);}
static get PICK_A_COMPANY_FOR_THIS_USER() {return I18N.Pick(["Pick a company for this user","Wählen Sie ein Unternehmen für diesen Benutzer"]);}
static get ADD_NEW() {return I18N.Pick(["Add New","Neu"]);}
static get HERE_YOU_CAN_DESCRIBE_ALL_THE_USERS_PROPERTIES() {return I18N.Pick(["Here you can describe all the user's properties.","Hier können Sie alle Eigenschaften des Benutzers beschreiben."]);}
static get CLIENT_NUMBER() {return I18N.Pick(["Client number","Kundennummer"]);}
static get LAUNCHER() {return I18N.Pick(["Launcher","Hauptmenü"]);}
static get MODIFY() {return I18N.Pick(["Modify","Ändern"]);}
static get MY_COMPANY() {return I18N.Pick(["My Company","Meine Firma"]);}
static get ROLE() {return I18N.Pick(["Role","Rolle"]);}
static get CHOOSE_A_ROLE_FOR_THIS_USER() {return I18N.Pick(["Choose a role for this user","Wählen Sie eine Rolle für diese Nutzer"]);}
static get WHAT_DO_YOU_WANNA_DO() {return I18N.Pick(["What do you wanna do","Was möchten Sie tun"]);}
static get REPEAT_PASSWORD() {return I18N.Pick(["Repeat Password","Wiederholen Sie das Passwort"]);}
static get REPEAT_THE_SAME_PASSWORD_HERE() {return I18N.Pick(["Repeat the same password here","Wiederholen Sie das Passwort"]);}
static get UPDATE_PASSWORD() {return I18N.Pick(["Update password","Passwort erneuern"]);}
static get TO_UPDATE_PASSWORD_CLICK_ON_THE_UPDATE_PASSWORD_BUTTON() {return I18N.Pick(["To update password click on the Update Password button","Um das Passwort zu aktualisieren, klicken Sie auf \"Passwort erneuern\""]);}
static get DASHBOARD() {return I18N.Pick(["Dashboard","Hauptmenü"]);}
static get DO_YOU_WANT_TO_CREATE_A_NEW_COMPANY() {return I18N.Pick(["Do you want to create a new company","Wollen Sie ein neues Unternehmen erstellen"]);}
static get DO_YOU_WANT_TO_EDIT_YOUR_COMPANY() {return I18N.Pick(["Do you want to edit your company","Wollen Sie Ihr Unternehmen bearbeiten"]);}
static get HERE_YOU_CAN_DESCRIBE_ALL_THE_COMPANYS_PROPERTIES() {return I18N.Pick(["Here you can describe all the company's properties","Hier können Sie alle Eigenschaften des Unternehmens beschreiben"]);}
static get NAME() {return I18N.Pick(["Name","Name"]);}
static get DESCRIBE_IT() {return I18N.Pick(["Describe it","Beschreibung"]);}
static get CONTACT() {return I18N.Pick(["Contact","Kontakt"]);}
static get DESCRIBE_HOW_TO_CONTACT_THIS_COMPANY() {return I18N.Pick(["Describe how to contact this company","Beschreiben Sie, wie diese Firma kontaktiert werden kann"]);}
static get DESCRIBE_THIS_COMPANY() {return I18N.Pick(["Describe this company","Beschreiben Sie diese Firma"]);}
static get UNIQUE_COMPANY_NAME() {return I18N.Pick(["Unique Company Name","Eindeutiger Firmenname"]);}
static get MENU() {return I18N.Pick(["Menu","Menü"]);}
static get TITLE() {return I18N.Pick(["Title","Titel"]);}
static get INQUIRIES() {return I18N.Pick(["Inquiries","Anfragen"]);}
static get LANGUAGE() {return I18N.Pick(["Language","Sprache"]);}
static get SET_AS_THE_COMPANYS_COVER_IMAGE() {return I18N.Pick(["Set as the company's cover image","Als Titelbild für Firma festlegen"]);}
static get PHOTOS() {return I18N.Pick(["Photos","Fotos"]);}
static get UPDATE_YOUR_COMPANY_INFORMATION() {return I18N.Pick(["Update your company information","Bearbeiten des Firmenprofils"]);}
static get VISIBLE_TO_ALL_OF_THE_USERS() {return I18N.Pick(["Visible to all of the users","Für alle Benutzer sichtbar"]);}
static get LOCATION() {return I18N.Pick(["Location","Ort"]);}
static get EQUIPMENT() {return I18N.Pick(["Equipment","Ausrüstung"]);}
static get SET_AS_THE_COMPANYS_LOGO() {return I18N.Pick(["Set as the company's logo","Als Firmenlogo festlegen"]);}
static get SELECT_THE_CONTACT_PERSON() {return I18N.Pick(["Select the contact person","Wählen Sie den Ansprechpartner"]);}
static get THIS_USER_WILL_BE_VISIBLE_TO_ALL_USERS() {return I18N.Pick(["This user will be visible to all users","Dieser Benutzer wird für alle Benutzer sichtbar sein"]);}
static get ADDRESS() {return I18N.Pick(["Address","Adresse"]);}
static get SEARCH() {return I18N.Pick(["Search","Suche"]);}
static get QUICK_SEARCH() {return I18N.Pick(["Quick Search","Schnelle Suche"]);}
static get FIELDS() {return I18N.Pick(["Fields","Felder"]);}
static get SEARCH_IN() {return I18N.Pick(["Search in","Suchen in"]);}
static get EMPLOYEES() {return I18N.Pick(["Employees","Angestellte"]);}
static get APPS() {return I18N.Pick(["Apps","Apps"]);}
static get REGIONS() {return I18N.Pick(["Regions","Regionen"]);}
static get FORGOT_YOUR_PASSWORD() {return I18N.Pick(["Forgot your password","Haben Sie Ihr Passwort vergessen"]);}
static get ALREADY_HAVE_USERNAME_AND_PASSWORD() {return I18N.Pick(["Already have username and password","Sie haben bereits Benutzernamen und Passwort"]);}
static get RESET_PASSWORD() {return I18N.Pick(["Reset password","Passwort zurücksetzen"]);}
static get CHECK_YOUR_EMAIL_FOR_FURTHER_INSTRUCTIONS() {return I18N.Pick(["Check your email for further instructions","Überprüfen Sie Ihre E-Mail für weitere Anweisungen"]);}
static get IF_YOUR_EMAIL_ADDRESS_EXISTS_IN_OUR_DATABASE_YOU_WILL_RECEIV__125() {return I18N.Pick(["If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.","Wenn Sie Ihre E-Mail-Adresse in unserer Datenbank vorhanden ist, erhalten Sie einen Passwort-Recovery-Link zu Ihrer E-Mail-Adresse in wenigen Minuten erhalten."]);}
static get PLEASE_WAIT() {return I18N.Pick(["Please wait","Bitte warten"]);}
static get INVALID_REQUEST() {return I18N.Pick(["Invalid Request","ungültige Anfrage"]);}
static get YOU_HAVE_SUCCESSFULLY_LOGGED_OUT() {return I18N.Pick(["You have successfully logged out","Sie haben sich erfolgreich abgemeldet"]);}
static get COMPANYS_LOGO() {return I18N.Pick(["Company's Logo","Firmenlogo"]);}
static get COMPANYS_COVER() {return I18N.Pick(["Company's Cover","Titelbild"]);}
static get PUBLIC_COMMENT() {return I18N.Pick(["Public comment","öffentlicher Kommentar"]);}
static get PRIVATE_COMMENT() {return I18N.Pick(["Private comment","Privater Kommentar"]);}
static get FIELD() {return I18N.Pick(["Field","Branche"]);}
static get SECTOR() {return I18N.Pick(["Sector","Bereich"]);}
static get PERIOD() {return I18N.Pick(["Period","Zeitraum"]);}
static get REGION() {return I18N.Pick(["region","Region"]);}
static get WEEK() {return I18N.Pick(["Week","Woche"]);}
static get YEAR() {return I18N.Pick(["Year","Jahr"]);}
static get SEND() {return I18N.Pick(["Send","Senden"]);}
static get NO_MATCH() {return I18N.Pick(["No match","Keine Übereinstimmung"]);}
static get SEND_REQUESTS() {return I18N.Pick(["Send requests","Anfragen senden"]);}
static get SENT_REQUESTS() {return I18N.Pick(["Sent requests","gesendete Kapazitätssuche"]);}
static get RECEIVED_REQUESTS() {return I18N.Pick(["Received requests","empfangene Kapazitätssuche"]);}
static get WAITING_FOR_RESPONSE() {return I18N.Pick(["Waiting for response","auf Antwort warten"]);}
static get APPROVED() {return I18N.Pick(["Approved","Angenommen"]);}
static get REJECTED() {return I18N.Pick(["Rejected","Abgelehnt"]);}
static get APPROVE() {return I18N.Pick(["Approve","Annehmen"]);}
static get REJECT() {return I18N.Pick(["Reject","Ablehnen"]);}
static get SERVICE() {return I18N.Pick(["Service","Leistung"]);}
static get REQUEST() {return I18N.Pick(["Request","Anfrage"]);}
static get OFFER() {return I18N.Pick(["Offer","Angebot"]);}
static get ARE_YOU_SURE() {return I18N.Pick(["Are you sure","Sind Sie sicher"]);}
static get LIST_VIEW() {return I18N.Pick(["List view","Listenansicht"]);}
static get CALENDAR() {return I18N.Pick(["Calendar","Kalender"]);}
static get INVALID_USERNAME_OR_PASSWORD() {return I18N.Pick(["Invalid username or password.","Ungültiger Benutzername oder Passwort."]);}
static get ENTER_YOUR_LOGIN_INFORMATION() {return I18N.Pick(["Enter your login information","Geben Sie Ihre Login-Daten ein"]);}
static get THE_PASSWORD_FIELD_IS_CASESENSITIVE() {return I18N.Pick(["The password field is case-sensitive.","Gross- und Kleinschreibung sind im Passwortfeld zu beachten."]);}
static get SEE_THE_LIST_OF_ALL_COMPANIES_IN_SICH() {return I18N.Pick(["See the list of all companies in SICH","Sehen Sie die Liste aller Unternehmen im SICH"]);}
static get MANAGE_THE_CAPACITY_REQUESTSOFFERS() {return I18N.Pick(["Manage the capacity requests/offers","Verwalten Sie die Kapazitätsanfragen / Angebote"]);}
static get UPDATE_YOUR_PERSONAL_INFORMATION() {return I18N.Pick(["Update your personal information","Aktualisieren Sie Ihre Daten"]);}
static get SEE_A_LIST_OF_USERS() {return I18N.Pick(["See a list of users","Hier finden Sie eine Liste der Benutzer"]);}
static get MANAGE_YOUR_UPLOADED_FILES() {return I18N.Pick(["Manage your uploaded files","Verwalten Sie Ihre hochgeladenen Dateien"]);}
static get EDIT_THE_REGIONS() {return I18N.Pick(["Edit the regions","Bearbeiten Sie die Regionen"]);}
static get EDIT_THE_SERVICES() {return I18N.Pick(["Edit the services","Bearbeiten Sie die Dienste"]);}
static get HERE_YOU_CAN_CREATE_OR_EDIT_SERVICES() {return I18N.Pick(["Here you can create or edit services","Hier können Sie Leistungen erstellen oder bearbeiten"]);}
static get TO_ADD_A_NEW_SERVICE_FILL_THE_EMPTY_FORM_AT_THE_BOTTOM_OF_TH__67() {return I18N.Pick(["To add a new service, fill the empty form at the bottom of this list.","Um eine neue Leistung hinzuzufügen, füllen Sie bitte das Formular am Ende der Liste aus."]);}
static get BEFORE_EDITING_OR_DELETING_ANY_ENTRY_PLEASE_CONSIDER_THAT_IT__121() {return I18N.Pick(["Before editing or deleting any entry please consider that it will affect all the previously made Capacities and Companies.","Bitte beachten Sie, dass das Änderung oder Löschen einer Leistung sich auch auf bestehende Firmen und Kapazitätsanfragen auswirkt!"]);}
static get TO_ADD_SUBCATEGORIES_TO_AN_EXISTING_SERVICE_CLICK_ON_THE_SER__72() {return I18N.Pick(["To add sub-categories to an existing service click on the \"Services\" button.","Um Unterkategorien zu einem bestehenden Eintrag hinzuzufügen, klicken Sie auf \"Dienste\"."]);}
static get LIST_OF_ALL_THE_AVAILABLE_COMPANIES() {return I18N.Pick(["List of all the available companies","Liste aller verfügbaren Unternehmen"]);}
static get QUICKLY_SEARCH_IN_THIS_LIST_BY_FILLING_SEARCH_FIELD() {return I18N.Pick(["Quickly search in this list by filling \"Search\" field.","Finden Sie Unternehmen schnell durch Ausfüllen des \"Suche\"-Feldes."]);}
static get THE_CONTACT_USER_WILL_BE_VISIBLE_TO_EVERYONE_AND_WILL_RECEIV__72() {return I18N.Pick(["The contact user will be visible to everyone and will receive the emails.","Der Kontakt-Benutzer ist für alle anderen Benutzer sichtbar und emfängt E-Mails."]);}
static get SENT_OFFERS() {return I18N.Pick(["Sent offers","gesendete Kapazitätsangebot"]);}
static get RECEIVED_OFFERS() {return I18N.Pick(["Received offers","empfangene Kapazitätsangebot"]);}
static get SPHERE_OF_ACTIVITY() {return I18N.Pick(["Sphere of activity","Leistungsregion"]);}
static get OFFER_CAPACITY() {return I18N.Pick(["Offer capacity","Kapazitätsangebot"]);}
static get SEARCH_CAPACITY() {return I18N.Pick(["Search capacity","Kapazitätssuche"]);}
static get THIS_IMAGE_IS_COMPANYS_COVER_IMAGE() {return I18N.Pick(["This image is company's cover image","Dieses Bild ist Unternehmen Cover-Bild"]);}
static get THIS_IMAGE_IS_COMPANYS_LOGO() {return I18N.Pick(["This image is company's logo","Dieses Bild ist Firmenlogo"]);}
static get DEPARTMENTS() {return I18N.Pick(["Departments","Abteilungen"]);}
static get THERE_IS_NO_REQUEST_IN_THIS_CATEGORY() {return I18N.Pick(["There is no request in this category","Es gibt keine Anfrage in dieser Kategorie"]);}
static get COMPANYS_NAME() {return I18N.Pick(["Company's name","Firmenname"]);}
static get DEPARTMENTS_NAME() {return I18N.Pick(["Department's name","Abteilung Name"]);}
static get WEBSITE() {return I18N.Pick(["Website","Webseite"]);}
static get CITY() {return I18N.Pick(["City","Stadt"]);}
static get ZIP_CODE() {return I18N.Pick(["Zip code","Postleitzahl"]);}
static get COUNTRY() {return I18N.Pick(["Country","Land"]);}
static get PHONE_NUMBER() {return I18N.Pick(["Phone number","Telefonnummer"]);}
static get DESCRIPTION() {return I18N.Pick(["Description","Beschreibung"]);}
static get SAVE() {return I18N.Pick(["Save","Speichern"]);}
static get URL() {return I18N.Pick(["Url","URL"]);}
static get TYPE() {return I18N.Pick(["Type","Art"]);}
static get SET_AS_THE_COMPANYS_ADVERTISEMENT_IMAGE() {return I18N.Pick(["Set as the company's advertisement image","Stellen Sie als das Werbebild des Unternehmens"]);}
static get UNSET_AS_THE_COMPANYS_ADVERTISEMENT_IMAGE() {return I18N.Pick(["Unset as the company's advertisement image","Verwendung als Werbebild aufheben"]);}
static get MEMBER() {return I18N.Pick(["Member","Mitglied"]);}
static get PARTNER() {return I18N.Pick(["Partner","Partner"]);}
static get ACTION() {return I18N.Pick(["Action","Aktion"]);}
static get YOUR_REQUEST_HAS_BEEN_SAVED_SUCCESSFULLY() {return I18N.Pick(["Your request has been saved successfully","Ihre Anfrage wurde erfolgreich gespeichert"]);}
static get YOUR_REQUEST_WILL_BE_PROCESSED_BY_OUR_STAFF_IN_NEXT_24_HOURS() {return I18N.Pick(["Your request will be processed by our staff in next 24 hours","Ihre Anfrage wird durch unsere Mitarbeiter in 24 Stunden verarbeitet werden"]);}
static get REGISTRATION_REQUESTS() {return I18N.Pick(["Registration Requests","Registrierungsanforderungen"]);}
static get ADD_A_NEW_USER_TO() {return I18N.Pick(["Add a new user to","Fügen Sie einen neuen Benutzer"]);}
static get CREATE_A_NEW_COMPANY() {return I18N.Pick(["Create a new company","Erstellen Sie ein neues Unternehmen"]);}
static get THERE_ARE_CURRENTLY_NO_REGISTRATION_REQUESTS() {return I18N.Pick(["There are currently no registration requests.","Es liegen noch keine Anmeldungen."]);}
static get FEEDBACK() {return I18N.Pick(["Feedback","Feedback"]);}
static get DO_YOU_HAVE_ANY_COMMENTS_OR_SUGGESTIONS_SEND_US_FEEDBACK() {return I18N.Pick(["Do you have any comments or suggestions? Send us feedback.","Haben Sie Anmerkungen oder Anregungen? Sende uns Feedback."]);}
static get PARTNER_COMPANIES_CANNOT_CREATE_CAPACITY_REQUESTS() {return I18N.Pick(["Partner companies cannot create capacity requests.","Partnerunternehmen können keine Kapazitätsanforderungen stellen."]);}
static get SIGN_UP() {return I18N.Pick(["Sign Up","Neu Registrieren"]);}
static get FEEDBACK_IS_SENT() {return I18N.Pick(["Feedback is sent.","Feedback wird gesendet."]);}
static get THIS_EMAIL_HAS_BEEN_USED_ALREADY() {return I18N.Pick(["This email has been used already.","Diese E-Mail wurde bereits verwendet."]);}
static get CHAT() {return I18N.Pick(["Chat","Chat"]);}
static get OPEN_SICH_WORKSPACE_ON_SLACK() {return I18N.Pick(["Open SICH Workspace on Slack","SICH-Bereich auf dem Chatsystem Slack öffnen"]);}
static get AN_UNEXPECTED_ERROR_HAS_HAPPENED_PLEASE_REFRESH_THIS_PAGE_AN__71() {return I18N.Pick(["An unexpected error has happened! Please refresh this page and try again.","Ein unerwarteter Fehler ist passiert! Bitte aktualisieren Sie diese Seite und versuchen Sie es erneut."]);}
static get DELETED() {return I18N.Pick(["Deleted","gelöscht"]);}
static get THE_DATA_HAS_BEEN_UPDATED_SUCCESSFULLY() {return I18N.Pick(["The data has been updated successfully","Die Daten wurden erfolgreich aktualisiert"]);}
static get EMPTY() {return I18N.Pick(["Empty","Leer"]);}
static get WHICH_CAPACITY_EMAILS_DO_YOU_WANT_TO_RECEIVE() {return I18N.Pick(["Which capacity emails do you want to receive?","Welche Kapazitäts-E-Mails möchten Sie erhalten?"]);}
static get ALL_CAPACITY_REQUESTS() {return I18N.Pick(["All capacity requests","Alle Kapazitätsanfragen"]);}
static get ONLY_IN_MY_REGION() {return I18N.Pick(["Only in my region","Nur in meiner Region"]);}
static get NONE() {return I18N.Pick(["None","Keine"]);}
static get USER_GUIDE() {return I18N.Pick(["User Guide","Kurzanleitung"]);}
static get REMARKS() {return I18N.Pick(["Remarks","Bemerkungen"]);}
static get NEW() {return I18N.Pick(["New","Neu"]);}
static get SORT_BY_CREATION_DATE() {return I18N.Pick(["Sort by creation date","Sortieren nach Erstellungsdatum"]);}
static get ASC() {return I18N.Pick(["Asc","Auf"]);}
static get DESC() {return I18N.Pick(["Desc","Ab"]);}
static get FROM() {return I18N.Pick(["From","Von"]);}
static get TO() {return I18N.Pick(["To","Bis"]);}
}
